import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReactComponent as IconArrow } from '../../assets/img/icons/angle-left.svg';
import { Slider } from '../Slider/Slider';
import s from './PhotoSlider.module.scss';
import { ReactComponent as IconPlay } from '../../assets/img/icons/play.svg';
import { useWindowSize } from '../../store/useWindowSize';

function PhotoSliderVideo({ src, onVideoPlaying, isStopped }) {
  const [isPlaying, setPlay] = useState(false);
  const videoRef = useRef(null);

  const onVideoClick = () => {
    setPlay(!isPlaying);
  };

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play();
        onVideoPlaying();
      } else {
        videoRef.current.pause();
      }
    }
  }, [videoRef, isPlaying, onVideoPlaying]);

  useEffect(() => {
    if (videoRef.current && isStopped) {
      setPlay(false);
    }
  }, [isStopped]);

  return (
    <div
      className={classNames(s.PhotoSliderVideo, {
        [s._playing]: isPlaying
      })}
      onClick={onVideoClick}
    >
      <video
        className={s.PhotoSlider__video}
        loop={false}
        autoPlay={false}
        muted
        playsInline
        ref={videoRef}
      >
        <source src={src} type="video/mp4" />
      </video>
      <div className={s.PhotoSliderVideo__play}>
        <i>
          <IconPlay />
        </i>
      </div>
    </div>
  );
}

export function PhotoSlider({ slides, className, sliderClassName }) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;

  const [slideIndex, setSlideIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [videoStopped, stopVideo] = useState(false);

  const onSlideChanging = () => {
    stopVideo(true);
  };
  const onSlideChanged = (index) => {
    setActiveIndex(index);
  };
  const onArrowClick = (direction) => {
    if (slideIndex === activeIndex) {
      if (direction === 'prev') {
        setSlideIndex(activeIndex === 0 ? slides.length - 1 : activeIndex - 1);
      } else {
        setSlideIndex(activeIndex === slides.length - 1 ? 0 : activeIndex + 1);
      }
    }
  };

  const onVideoPlaying = () => {
    stopVideo(false);
  };

  return (
    <Slider
      className={classNames(s.PhotoSlider, className)}
      sliderClassName={classNames(s.PhotoSlider__slick, sliderClassName)}
      selectedIndex={slideIndex}
      onSlideBeforeChange={onSlideChanging}
      onSlideAfterChange={onSlideChanged}
      ext={
        <div className={s.PhotoSlider__arrows}>
          {!isMobile && (
            <div
              className={classNames(s.PhotoSlider__arrow, s._prev)}
              onClick={() => onArrowClick('prev')}
            >
              <i>
                <IconArrow />
              </i>
            </div>
          )}
          <div className={s.PhotoSlider__counter}>
            <p>
              {activeIndex + 1}/{slides.length}
            </p>
          </div>
          {!isMobile && (
            <div
              className={classNames(s.PhotoSlider__arrow, s._next)}
              onClick={() => onArrowClick('next')}
            >
              <i>
                <IconArrow />
              </i>
            </div>
          )}
        </div>
      }
    >
      {slides.map((slide, i) => (
        <div key={i} className={s.PhotoSlider__slide}>
          {slide.isVideo ? (
            <PhotoSliderVideo
              isStopped={videoStopped}
              onVideoPlaying={onVideoPlaying}
              src={slide.src}
            />
          ) : (
            <img className={s.PhotoSlider__image} src={slide.src} alt="" />
          )}
        </div>
      ))}
    </Slider>
  );
}
