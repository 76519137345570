import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts/fonts.scss';
import './index.scss';
import App from './App';
import { STORE_AUTH, STORE_EVENTS } from './store/stores';
import { ProstoreContext } from '@proscom/prostore-react';
import { BrowserRouter } from 'react-router-dom';
import { authClient, defaultClient, registerAuthInterceptor } from './Client';
import {
  AxiosClientsManager,
  AxiosClientsContext
} from '@proscom/prostore-axios-react';
import { AuthStore } from './store/AuthStore';
import { EventsStore } from './store/EventsStore';
import { LocalStorageStore } from './store/LocalStorageStore';

const localStorageStore = new LocalStorageStore(localStorage);
localStorageStore.registerListener();

const authStore = new AuthStore({
  localStorageStore,
  client: authClient
});
authStore.subscribe();

registerAuthInterceptor(authStore);

const eventsStore = new EventsStore();

const stores = {
  [STORE_AUTH]: authStore,
  [STORE_EVENTS]: eventsStore
};

const clients = new AxiosClientsManager({
  default: defaultClient
});

ReactDOM.render(
  <BrowserRouter>
    <ProstoreContext.Provider value={stores}>
      <AxiosClientsContext.Provider value={clients}>
        <App />
      </AxiosClientsContext.Provider>
    </ProstoreContext.Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
