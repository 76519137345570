import React from 'react';
import { Dropdown } from '../../../common/Dropdown/Dropdown';
import { Document } from '../../../common/Document/Document';
import s from './AnalyticsBox.module.scss';
import { EMDASH } from '../../../utils/utf';

export function AnalyticsBox({ protests, protestId }) {
  const protest = protestId ? protests.find((pr) => pr.id === protestId) : null;
  // console.log('protest', protest);

  const description =
    protest && protest.description ? protest.description : null;
  const descOccasion =
    description && description.occasion ? description.occasion : null;
  const descMethods =
    description && description.methods ? description.methods : null;
  const descStrategy =
    description && description.opposition_strategy
      ? description.opposition_strategy
      : null;

  const attachments =
    protest && protest.attachments
      ? protest.attachments
          .filter((item) => item.type !== 'main')
          .map((item) => item.file)
      : [];

  const attachmentsEl = attachments ? attachments.map(attach => {
    return (<Document {...attach} />);
  }) : EMDASH;

  return (
    <div className={s.AnalyticsBox}>
      <div className={s.AnalyticsBox__content}>
        <ul className={s.AnalyticsBox__infoList}>
          <li className={s.AnalyticsBox__infoItem}>
            <Dropdown
              title={descOccasion ? descOccasion.title : null}
              desc={descOccasion ? descOccasion.description : null}
            />
          </li>
          <li className={s.AnalyticsBox__infoItem}>
            <Dropdown
              title={descMethods ? descMethods.title : null}
              desc={descMethods ? descMethods.description : null}
            />
          </li>
          <li className={s.AnalyticsBox__infoItem}>
            <Dropdown
              title={descStrategy ? descStrategy.title : null}
              desc={descStrategy ? descStrategy.description : null}
            />
          </li>
        </ul>
      </div>
      <div className={s.AnalyticsBox__ext}>
        <div className={s.AnalyticsBox__extTitle}>
          <h2>Дополнительная аналитика</h2>
        </div>
        <div className={s.AnalyticsBox__doc}>
          {attachmentsEl}
        </div>
      </div>
    </div>
  );
}
