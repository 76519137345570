import axios from 'axios';

export const defaultClient = axios.create();

defaultClient.interceptors.request.use((request) => {
  let urlParams = new URLSearchParams(window.location.search);
  if(urlParams.has('token')) request.headers.token = urlParams.get('token');
  return request;
})

export const authClient = axios.create();

export function registerAuthInterceptor(authStore) {
  defaultClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        // Сессия истекла
        authStore.setExpired();
      }
      return Promise.reject(error);
    }
  );

}
