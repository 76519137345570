import React from 'react';
import s from './Layouts.module.scss';
import classNames from 'classnames';

export function MainLayout({ topBar, right, children, ext, className }) {
    let layoutType = topBar === false ? s.MainLayoutWithoutTopbar : s.MainLayout;
  return (
    <div className={classNames(layoutType, className)}>
      {topBar}
      <div className={s.MainLayout__main}>
        <div className={s.MainLayout__grid}>{children}</div>
      </div>
      {/*<div className={s.MainLayout__right}>{right}</div>*/}
      {ext}
    </div>
  );
}
