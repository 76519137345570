import { DefaultLayout } from './DefaultLayout';
import { Routes } from '../common/Routes';
import IndexPage from './index/IndexPage';
import NotFoundPage from './notFound/NotFoundPage';
import LoginPage from './loginPage/loginPage';
import { RequireAuth } from '../RequireAuth';

const routes = [
  {
    path: '/login',
    exact: true,
    component: DefaultLayout(LoginPage)
  },
  {
    path: '/',
    exact: true,
    component: DefaultLayout(RequireAuth(IndexPage))
  },
  {
    component: NotFoundPage
  }
];

const AppRoutes = Routes(routes);

export default AppRoutes;
