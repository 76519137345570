import IconPdf from '../assets/img/icons/docs/pdf.svg';
import IconXsl from '../assets/img/icons/docs/excel.svg';
import IconPP from '../assets/img/icons/docs/powerpoint.svg';
import IconDoc from '../assets/img/icons/docs/word.svg';
import IconVVP from '../assets/img/content/indicators/Arrow_up.svg';
import IconPeople from '../assets/img/content/indicators/people.svg';
import IconEco from '../assets/img/content/indicators/Money.svg';
import IconInternet from '../assets/img/content/indicators/Wi-Fi.svg';
import IconJob from '../assets/img/content/indicators/Work.svg';
import { EMDASH } from './utf';
import { mediaWidthMobile, mediaWidthTablet } from '../_vars.scss';

export function getRemSize() {
  if (typeof window !== 'undefined') {
    return extractPxValue(
      getComputedStyle(window.document.body, null).fontSize
    );
  }
  return 16;
}
export function extractUnitValue(str) {
  const match = str.match(/([\d.]+)(\w+)/);
  if (!match) {
    throw new Error(`Cannot extract value from '${str}'`);
  }
  const value = +match[1];
  const unit = match[2];
  if (Number.isNaN(value) || !unit) {
    throw new Error(`Cannot extract value from '${str}'`);
  }
  return [value, unit];
}
export function extractPxValue(str) {
  const [value, unit] = extractUnitValue(str);
  if (unit === 'rem' || unit === 'em') {
    return value * getRemSize();
  } else if (unit === 'px') {
    return value;
  } else {
    throw new Error(
      `Unknown unit '${unit}' when extracting px value from '${str}'`
    );
  }
}

export const MEDIA_QUERIES = {
  mobile: extractPxValue(mediaWidthMobile),
  tablet: extractPxValue(mediaWidthTablet)
};

export const DOC_TYPES = {
  default: {
    icon: IconPdf
  },
  pdf: {
    icon: IconPdf
  },

  xls: {
    icon: IconXsl
  },
  xlsx: {
    icon: IconXsl
  },

  ppt: {
    icon: IconPP
  },
  pptx: {
    icon: IconPP
  },

  doc: {
    icon: IconDoc
  },
  docx: {
    icon: IconDoc
  }
};

export const EVENTS_SIDES = {
  default: {
    color: 'blue'
  },
  government: {
    color: 'blue'
  },
  opposition: {
    color: 'red'
  }
};

export const INDICATORS = [
  {
    icon: null,
    name: 'country',
    title: 'Страна',
    value: EMDASH,
    link: null,
    isCountry: true
  },
  {
    icon: IconVVP,
    name: 'gdp',
    title: 'Рост ВВП',
    value: EMDASH
  },
  {
    icon: IconPeople,
    name: 'population',
    title: 'Население',
    value: EMDASH
  },
  {
    icon: IconEco,
    name: 'economic_inequality',
    title: 'Экономическое неравенство',
    value: EMDASH
  },
  {
    icon: IconInternet,
    name: 'internet_prevalence',
    title: 'Проникновение интернета',
    value: EMDASH
  },
  {
    icon: IconJob,
    name: 'unemployment',
    title: 'Безработица',
    value: EMDASH
  }
];

export const dateFormatString = 'dd MMMM yyyy, H:mm';
export const dateFormatStringNoTime = 'dd MMMM yyyy';
export const dateFormatStringSimple = 'dd.MM.yy';
// export const dateFormatStringTimeOnly = 'H:mm';
