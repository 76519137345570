import React from 'react';
import { Route, Switch } from 'react-router-dom';

export function Routes(routes) {
  return function RoutesComp({ location, match }) {
    const pathPrefix = (match && match.path) || '';
    return (
      <Switch location={location}>
        {routes.map((props, i) => {
          const path = props.path && `${pathPrefix}${props.path}`;
          const newProps = {
            ...props,
            path
          };
          return <Route key={i} {...newProps} />;
        })}
      </Switch>
    );
  };
}
