import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowIcon } from '../../assets/img/icons/angle-down.svg';
import { ReactComponent as BurgerIcon } from '../../assets/img/icons/menu.svg';
import classNames from 'classnames';
import { Select } from '../Select/Select';
import { useWindowSize } from '../../store/useWindowSize';
import userAva from '../../assets/img/user1.png';
import s from './TopBar.module.scss';
import {useAuth} from "../../config";

function ProtestFilter({ values, activeValue, onChange }) {
  const options = values
    ? values
        .sort((a, b) => (a.title > b.title ? 1 : b.title > a.title ? -1 : 0))
        .map((val) => {
          return {
            label: val.title,
            value: val.id
          };
        })
    : [];
  const activeOption = options.filter((opt) => opt.value === activeValue);

  // console.log(activeValue, activeOption, options);

  const onValueChange = (value) => {
    onChange(value);
  };

  return (
    <div className={s.ProtestFilter}>
      {options && (
        <Select
          className={s.ProtestFilter__select}
          values={options}
          value={activeOption}
          onChange={onValueChange}
        />
      )}
    </div>
  );
}

function UserMenu({ onLogoutClick }) {
  return (
    <div className={s.TopBarUser__menu}>
      <div className={s.TopBarUser__logoutBtn} onClick={onLogoutClick}>
        Выйти
      </div>
    </div>
  );
}

function TopBarUser({
  ava,
  name,
  onLogoutClick,
  menuActive,
  onMenuOpen,
  onMenuClose,
  isMobile
}) {
  const avaStyle = {
    backgroundImage: `url(${ava || userAva})`
  };

  return useAuth ? (
    <div className={s.TopBarUser}>
      <div
        className={s.TopBarUser__content}
        onClick={menuActive ? onMenuClose : onMenuOpen}
      >
        <div className={s.TopBarUser__ava} style={avaStyle}>
          <div className={s.TopBarUser__indicator} />
        </div>
        <div className={s.TopBarUser__title}>
          <p>{name}</p>
          <div className={s.TopBarUser__navIcon}>
            <ArrowIcon />
          </div>
        </div>
      </div>
      {!isMobile && menuActive && <UserMenu onLogoutClick={onLogoutClick} />}
    </div>
  ) : false;
}

export function TopBar({
  protests,
  activeProtest,
  onProtestChange,
  user,
  onLogout
}) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile;

  const [userMenuActive, setUserMenuActive] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [upScrolling, setUpScrolling] = useState(0);

  const lastScrollTopRef = useRef(0);
  lastScrollTopRef.current = lastScrollTop;

  const onScroll = useCallback(() => {
    if (!isMobile) return;

    const lastST = lastScrollTopRef.current;

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // console.log(lastST, scrollTop);
    setUpScrolling(scrollTop <= lastST);

    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile) return;
    setLastScrollTop(0);
    onScroll();
    window.addEventListener('scroll', onScroll, false);
    return () => window.removeEventListener('scroll', onScroll);
  }, [isMobile, onScroll]);

  const onUserMenuOpen = () => {
    setUserMenuActive(true);
  };
  const onUserMenuClose = () => {
    setUserMenuActive(false);
  };

  return (
    <div className={classNames(s.TopBar, 'topbar')}>
      <div className={s.TopBar__inner}>
        <div className={s.TopBar__main}>
          <div
            className={s.TopBar__navBtn}
            onClick={
              isMobile
                ? userMenuActive
                  ? onUserMenuClose
                  : onUserMenuOpen
                : null
            }
          >
            <BurgerIcon />
          </div>
          <div className={s.TopBar__title}>
            <p>Хронология</p>
          </div>
          {!isMobile && (
            <div className={s.TopBar__filter}>
              <ProtestFilter
                values={protests}
                activeValue={activeProtest}
                onChange={onProtestChange}
              />
            </div>
          )}
        </div>
        {!isMobile && (
          <div className={s.TopBar__ext}>
            <div className={s.TopBar__user}>
              <TopBarUser
                {...user}
                onLogoutClick={onLogout}
                menuActive={userMenuActive}
                onMenuOpen={onUserMenuOpen}
                onMenuClose={onUserMenuClose}
                isMobile={isMobile}
              />
            </div>
          </div>
        )}
      </div>
      {isMobile && (
        <>
          <div
            className={classNames(s.TopBar__filter, {
              [s._visible]: upScrolling
            })}
          >
            <ProtestFilter
              values={protests}
              activeValue={activeProtest}
              onChange={onProtestChange}
            />
          </div>

          {userMenuActive && <UserMenu onLogoutClick={onLogout} />}
        </>
      )}
    </div>
  );
}
