import { parse as parseQuery, stringify as stringifyQuery } from 'query-string';
import { useCallback } from 'react';
import { useValueRef } from './useValueRef';

export function pushQuery(history, location, changes) {
  const query = parseQuery(location.search);

  history.push({
    ...location,
    search: stringifyQuery({
      ...query,
      ...changes
    })
  });
}

export function useUrlQuery(location, history) {
  const query = parseQuery(location.search);
  const ref = useValueRef({ location, query });

  const changeQuery = useCallback(
    (changes) => {
      pushQuery(history, ref.current.location, changes);
    },
    [ref, history]
  );

  return [query, changeQuery];
}
