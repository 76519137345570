import React from 'react';
import { DatePeriod } from '../../../common/DatePeriod/DatePeriod';
import { Indicator } from '../../../common/Indicator/Indicator';
import { useAxiosQuery } from '@proscom/prostore-axios-react';
import { EMDASH } from '../../../utils/utf';
import { dateFormatStringNoTime, INDICATORS } from '../../../utils/constants';
import { reformatDate } from '../../../utils/date';
import { PhotoSlider } from '../../../common/PhotoSlider/PhotoSlider';
import s from './TitleBox.module.scss';
import { apiPath } from '../../../config';

const countriesQueryOptions = {
  query: {
    url: apiPath + '/countries',
    method: 'get',
    params: {
      limit: 'all'
    }
  }
};

export function TitleBox({ protests, protestId, dates }) {
  const protest = protestId ? protests.find((pr) => pr.id === protestId) : null;
  // console.log('protest', protest);

  const countriesQuery = useAxiosQuery({
    queryOptions: countriesQueryOptions
  });

  const country =
    countriesQuery.state.data &&
    countriesQuery.state.data.success &&
    protest &&
    protest.country
      ? countriesQuery.state.data.data.countries.find(
          (c) => c.id === protest.country.id
        )
      : null;

  const countryData =
    country && country.description && country.description.shared_data
      ? country.description.shared_data
      : null;

  // console.log('country', country);

  const cityName = protests.length && protest ? protest.title : null;

  const indicators = INDICATORS.map((ind) => ({ ...ind }));
  if (countryData) {
    for (let indicator of indicators) {
      if (indicator.name !== 'country') {
        indicator.value = countryData[indicator.name] || indicator.value;
      }
    }
  }

  if (country) {
    const countryIndicator = indicators.find((ind) => ind.name === 'country');
    countryIndicator.icon = country.flag ? country.flag.src : null;
    // COUNTRIES_ICONS[country.id] || COUNTRIES_ICONS.default;
    countryIndicator.value = country.name || countryIndicator.value;
    countryIndicator.link = country.link || countryIndicator.value;
  }

  // console.log(indicators);

  const images =
    protest && protest.attachments && protest.attachments.length > 0
      ? protest.attachments
          .filter((item) => item.type === 'main')
          .map((item) => item.file)
      : null;

  // console.log(images);

  const sinceDate =
    dates && dates.firstDate
      ? reformatDate(dates.firstDate, dateFormatStringNoTime)
      : null;
  const tillDate =
    dates && dates.lastDate
      ? reformatDate(dates.lastDate, dateFormatStringNoTime)
      : null;

  return (
    <div className={s.TitleBox}>
      <div className={s.TitleBox__content}>
        <div className={s.TitleBox__title}>
          <h1>{cityName ? `${cityName}` : EMDASH}</h1>
        </div>
        <div className={s.TitleBox__dates}>
          <DatePeriod dateSince={sinceDate} dateTill={tillDate} />
        </div>
        <div className={s.TitleBox__indicators}>
          <ul className={s.TitleBox__indicatorsList}>
            {indicators.map((ind, i) => (
              <li key={i} className={s.TitleBox__indicatorsItem}>
                <Indicator
                  icon={ind.icon}
                  title={ind.title}
                  value={ind.value}
                  {...ind}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      {images && images.length > 0 && (
        <div className={s.TitleBox__slider}>
          {images.length > 1 ? (
            <PhotoSlider className={s.TitleSlider} slides={images} />
          ) : (
            <div
              className={s.TitleBox__image}
              style={{ backgroundImage: `url(${images[0].src})` }}
            />
          )}
        </div>
      )}
    </div>
  );
}
