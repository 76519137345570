import React, { useState } from 'react';
import s from './Dropdown.module.scss';
import { ReactComponent as IconArrow } from '../../assets/img/icons/angle-down.svg';
import classNames from 'classnames';
import HTMLParse from 'html-react-parser';
import { EMDASH } from '../../utils/utf';

export function Dropdown({ className, title, desc }) {
  const [isOpened, setOpened] = useState(false);

  const toggleDD = () => {
    setOpened(!isOpened);
  };

  return (
    <div
      className={classNames(s.Dropdown, className, {
        [s._opened]: isOpened
      })}
    >
      <div className={s.Dropdown__head} onClick={toggleDD}>
        <div className={s.Dropdown__title}>
          {title ? HTMLParse(title) : <p>{EMDASH}</p>}
        </div>
        <div className={s.Dropdown__arrow}>
          <IconArrow />
        </div>
      </div>
      {isOpened && (
        <div className={s.Dropdown__dd}>
          <div className={s.Dropdown__content}>
            {desc ? HTMLParse(desc) : <p>{EMDASH}</p>}
          </div>
        </div>
      )}
    </div>
  );
}
