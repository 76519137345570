import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ReactComponent as IconCal } from '../../../assets/img/icons/calendar.svg';
import { ReactComponent as IconCirc } from '../../../assets/img/icons/circle.svg';
import { EventsIndicator } from '../EventsIndicator/EventsIndicator';
import classNames from 'classnames';
import { declinedTitle } from '../../../utils/formatNumber';
import s from './EventsIndicatorBox.module.scss';
import { lDiffOfDates } from '../../../utils/date';
import { useWindowSize } from '../../../store/useWindowSize';

const eventsAmountTitles = ['событие', 'события', 'событий'];
const daysAmountTitles = ['день', 'дня', 'дней'];

function EventsDate({ icon: Icon, text }) {
  return (
    <div className={s.EventsDate}>
      <div className={s.EventsDate__icon}>
        <Icon />
      </div>
      <div className={s.EventsDate__text}>
        <p>{text}</p>
      </div>
    </div>
  );
}

function EventsIndicatorFlyBox({
  events,
  firstLastDates,
  className,
  isMobile,
  forwardRef
}) {
  return (
    <div
      className={classNames(s.EventsIndicatorFlyBox, className)}
      ref={forwardRef}
    >
      {!isMobile && (
        <div className={s.EventsIndicatorBox__indicator}>
          <EventsIndicator firstLastDates={firstLastDates} events={events} />
        </div>
      )}
      <div className={s.EventsIndicatorBox__sides}>
        <div className={s.EventsIndicatorBox__sidesGrid}>
          <div className={classNames(s.EventsIndicatorBox__side, s._opposite)}>
            <p>Оппозиция</p>
          </div>
          <div
            className={classNames(s.EventsIndicatorBox__side, s._government)}
          >
            <p>Действующая власть</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function EventsIndicatorBox({ firstLastDates, events }) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile;
  const [isFixed, setFixed] = useState(false);

  const flyBoxRef = useRef(null);
  const topBar = useRef(null);

  const withoutTopbar = !topBar.current;

  console.log('topBar');
  console.log(topBar);


  console.log('flyBoxRef');
  console.log(flyBoxRef);

  const setPosition = () => {
    if (flyBoxRef.current) {
      let topBarHeight = !withoutTopbar ? topBar.current.clientHeight : 0;
      const isOverScrolled =
        window.scrollY >=
        flyBoxRef.current.offsetTop - topBarHeight;
      setFixed(isOverScrolled);
    }
  };


  // console.log(isFixed);

  useEffect(() => {
    if (!isMobile) {
      topBar.current = document.getElementsByClassName('topbar')[0];
      setPosition();
      window.addEventListener('scroll', setPosition);
    }
    return () => window.removeEventListener('scroll', setPosition);
  }, [isMobile]);

  const daysAmount = useMemo(() => {
    let amount = firstLastDates
      ? lDiffOfDates(firstLastDates.lastDate, firstLastDates.firstDate)
      : null;

    return amount !== null
      ? `${amount} ${declinedTitle(amount, daysAmountTitles)}`
      : null;
  }, [firstLastDates]);

  const eventsAmount = useMemo(() => {
    return events
      ? `${events.length} ${declinedTitle(events.length, eventsAmountTitles)}`
      : null;
  }, [events]);

  return (
    <>
      <div className={s.EventsIndicatorBox}>
        <div className={s.EventsIndicatorBox__main}>
          <div className={s.EventsIndicatorBox__title}>
            <h2>Хронология событий</h2>
            <div className={s.EventsIndicatorBox__date}>
              {daysAmount && (
                <div className={s.EventsIndicatorBox__dateItem}>
                  <EventsDate icon={IconCal} text={daysAmount} />
                </div>
              )}
              <div className={s.EventsIndicatorBox__dateItem}>
                <EventsDate icon={IconCirc} text={eventsAmount} />
              </div>
            </div>
          </div>
        </div>
        <EventsIndicatorFlyBox
          forwardRef={flyBoxRef}
          events={events}
          firstLastDates={firstLastDates}
          isMobile={isMobile}
        />
      </div>
      {!isMobile && (
        <EventsIndicatorFlyBox
          className={classNames('indicatorBox', s._fixed, {
            [s._visible]: isFixed,
            [s._pinTop]: withoutTopbar
          })}
          events={events}
          firstLastDates={firstLastDates}
        />
      )}
    </>
  );
}
