import React from 'react';
import { useStore } from '@proscom/prostore-react';
import { STORE_AUTH } from './store/stores';
import { Redirect } from 'react-router';
import {useAuth} from "./config";

// import HasNoAccessPage from './HasNoAccessPage/HasNoAccessPage';

export function RequireAuth(Component, checkAccess = false) {
  return function RequireAuthComponent(props) {

    let urlParams = new URLSearchParams(window.location.search);


    if(useAuth && !urlParams.has('token')) {
      const [auth, authStore] = useStore(STORE_AUTH);

      console.log('auth', auth);

      if (!auth.loaded) return;
      if (!authStore.isLoggedIn()) {
        console.log('not logged in');
        return <Redirect to={'/login'} />;
      }
      if (checkAccess && !checkAccess(auth)) {
        console.log('no access');
        return <Redirect to={'/'} />;
      }
    }

    return <Component {...props} />;
  };
}
