import React from 'react';
import s from './Input.module.scss';
import classNames from 'classnames';

export function Input({
  type,
  className,
  required = false,
  placeholder,
  forwardedRef
}) {
  return (
    <div className={classNames(s.Input, className)}>
      <input
        type={type}
        className={s.Input__inp}
        placeholder={placeholder}
        ref={forwardedRef}
        required={required}
      />
    </div>
  );
}
