import React from 'react';
import { DOC_TYPES } from '../../utils/constants';
import { EMDASH } from '../../utils/utf';
import s from './Document.module.scss';
import { getFileSize } from '../../utils/data';

export function Document({ extension, file_name, id_external, src, size }) {
  const icon = DOC_TYPES[extension]
    ? DOC_TYPES[extension].icon
    : DOC_TYPES.default.icon;
  const fileName = id_external || file_name || EMDASH;
  const fileSize = size ? getFileSize(size) : null;

  return (
    <div className={s.Document}>
      <a
        className={s.Document__link}
        href={src}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={s.Document__icon}>
          <img src={icon} alt="" />
        </div>
        <div className={s.Document__info}>
          <div className={s.Document__title}>
            <p>{fileName}</p>
          </div>
          {fileSize && (
            <div className={s.Document__size}>
              <p>
                {fileSize.size} {fileSize.suffix}
              </p>
            </div>
          )}
        </div>
      </a>
    </div>
  );
}
