import {
  endOfWeek,
  format,
  isBefore,
  isEqual,
  isSameWeek,
  isValid,
  parseISO,
  startOfWeek,
  differenceInCalendarDays
} from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import { EMDASH } from './utf';

export const DATE_FORMAT_RUSSIAN = 'dd.MM.yyyy';
export const DATE_FORMAT_ISO_DATE = 'yyyy-MM-dd';

export function reformatDate(
  isoDate,
  formatString,
  options = {},
  defaultValue = EMDASH
) {
  if (!isoDate) return defaultValue;
  const date = parseISO(isoDate);
  if (!date) return defaultValue;
  return format(date, formatString || DATE_FORMAT_RUSSIAN, {
    ...options,
    locale: ruLocale
  });
}

export function lStartOfWeek(date) {
  return startOfWeek(date, { weekStartsOn: 1 });
}

export function lEndOfWeek(date) {
  return endOfWeek(date, { weekStartsOn: 1 });
}

export function lIsSameWeek(dateLeft, dateRight) {
  return isSameWeek(dateLeft, dateRight, { weekStartsOn: 1 });
}

export function lFormat(date, formatString, options = {}) {
  return format(date, formatString, { ...options, locale: ruLocale });
}

export function formatDateIso(date) {
  return format(date, DATE_FORMAT_ISO_DATE);
}

export function isBeforeOrEqual(dateLeft, dateRight) {
  return isBefore(dateLeft, dateRight) || isEqual(dateLeft, dateRight);
}

export function tryParseIso(string) {
  if (!string) return null;
  const date = parseISO(string);
  if (isValid(date)) {
    return date;
  }
  return null;
}

export function lDiffOfDates(lastDate, earlyDate) {
  const leftDate = tryParseIso(lastDate);
  const rightDate = tryParseIso(earlyDate);
  return differenceInCalendarDays(leftDate, rightDate) || null;
}
