import React from 'react';
import { ErrorBoundary } from '../common/ErrorBoundary';
import ErrorPage from '../common/ErrorPage';

export const DefaultLayout = (Comp) => (props) => {
  return (
    <div id="wrapper-offset">
      <ErrorBoundary component={ErrorPage}>
        <Comp {...props} />
      </ErrorBoundary>
    </div>
  );
};
