import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Event } from '../Event/Event';
import classNames from 'classnames';
import { EventIcon } from '../../../common/EventIcon/EventIcon';
import { reformatDate } from '../../../utils/date';
import { EMDASH } from '../../../utils/utf';
import { useUrlQuery } from '../../../utils/useUrlQuery';
import {
  dateFormatString,
  dateFormatStringNoTime
} from '../../../utils/constants';
import { STORE_EVENTS } from '../../../store/stores';
import { useStore } from '@proscom/prostore-react';
import { useInView } from 'react-intersection-observer';
import { useWindowSize } from '../../../store/useWindowSize';
import { Loader } from '../../../common/Loader/Loader';
import { Button } from '../../../common/Button/Button';
import s from './EventsList.module.scss';
const helpers = require('dom-helpers');

function EventsItem({
  event,
  selectedEventId,
  activeEventId,
  scrollOffset,
  onEventSelected,
  onEventInView
}) {
  const [eventRef, inView] = useInView({
    rootMargin: `-${scrollOffset}px 0px -30px 0px`,
    // rootMargin: `-300px 0px -150px 0px`,
    threshold: 1
  });

  useEffect(() => {
    // console.log('inView', inView, event.id);
    onEventInView(event.id, inView);
  }, [event.id, inView]); //eslint-disable-line

  const side = event.side === 'opposition' ? 'left' : 'right';

  const date = event.event_datetime
    ? event.has_time
      ? reformatDate(event.event_datetime, dateFormatString)
      : reformatDate(event.event_datetime, dateFormatStringNoTime)
    : EMDASH;

  return (
    <div className={classNames(s.EventsList__event, s[`_${side}`])}>
      <div className={s.EventsList__eventDot} ref={eventRef}>
        <div className={s.EventsList__eventIcon}>
          <EventIcon
            className={s.EventsList__eventIcon__icon}
            side={event.side}
            hasViolence={event.violence || false}
            isActive={
              event.id === activeEventId || event.id === selectedEventId
            }
          />
        </div>
        <div className={s.EventsList__eventDate}>
          <p>{date}</p>
        </div>
      </div>
      <Event
        className={s.Event}
        id={event.id}
        images={event.attachments}
        title={event.title}
        desc={event.description}
        isSelected={event.id === selectedEventId}
        onClick={onEventSelected}
      />
    </div>
  );
}

export function EventsList({
  events,
  eventsFirstLoading,
  eventsLoading,
  allEventsLoaded,
  location,
  history,
  onGetMoreEvents
}) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile;

  const [eventsStoreState, eventsStore] = useStore(STORE_EVENTS);
  const [inViewEvents, setInViewEvents] = useState([]);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [nodesHaveSet, setNodesHaveSet] = useState(false);
  const [query, changeQuery] = useUrlQuery(location, history);
  const selectedEventId = query.event ? +query.event : undefined;
  const activeEventId = eventsStoreState.activeEventId;

  const isLoading = eventsLoading;
  const isLoaded = events.length > 0 && !eventsLoading;
  const isEmpty = !events.length && !eventsLoading;

  /**
   * эвенты приходят не по порядку eventId
   */

  const eventsBoxRef = useRef(null);
  const listRef = useRef(null);
  const sideLeftRef = useRef(null);
  const sideRightRef = useRef(null);
  const topBar = useRef(null);
  const indicatorBox = useRef(null);

  const onEventSelected = (eventId) => {
    changeQuery({ event: eventId });
  };
  const onEventInView = useCallback(
    (eventId, inView) => {
      if (nodesHaveSet) {
        let eventsArray = inViewEvents.map((ind) => ind);

        if (!inView) {
          const eventIndex = eventsArray.indexOf(eventId);
          // console.log(eventsArray, eventIndex);
          if (eventIndex !== -1) {
            eventsArray.splice(eventIndex, 1);
          }
        } else {
          eventsArray = [eventId, ...eventsArray];
          // eventsArray.sort((a, b) => a - b);
        }

        // console.log('eventsArray', eventsArray);
        setInViewEvents(eventsArray);
      }
    },
    [nodesHaveSet, inViewEvents]
  );

  useEffect(() => {
    // console.log('inViewEvents', inViewEvents);
    eventsStore.selectEvent(inViewEvents[0] || null);
  }, [eventsStore, inViewEvents]);

  const defaultMargin = 48;
  useEffect(() => {
    if (!isMobile) {
      if (events && events.length && sideLeftRef && sideRightRef) {
        const children = listRef.current.childNodes;
        setNodesHaveSet(false);

        if (children.length) {
          sideLeftRef.current.style.height = 0;
          sideRightRef.current.style.height = 0;

          for (let i = 0; i < events.length; i++) {
            const event = events[i];
            const node = children[i];
            const currentSide = event.side;

            let currentSideRef = null;
            let otherSideRef = null;
            if (currentSide === 'opposition') {
              currentSideRef = sideLeftRef;
              otherSideRef = sideRightRef;
            } else {
              currentSideRef = sideRightRef;
              otherSideRef = sideLeftRef;
            }

            if (i === 0) {
              node.style.top = 0;
            } else {
              const prevEvent = events[i - 1];
              const prevNode = children[i - 1];
              const prevSide = prevEvent.side;
              const prevNodeTop = +helpers.position(prevNode).top;
              const prevNodeHeight = +prevNode.clientHeight;

              if (prevSide === currentSide) {
                node.style.top =
                  currentSideRef.current.clientHeight + defaultMargin + 'px';
              } else {
                const currentSideHeight = currentSideRef.current.clientHeight;
                const otherSideHeight = otherSideRef.current.clientHeight;
                const sidesHeightDelta = currentSideHeight - otherSideHeight;

                if (
                  sidesHeightDelta < 0 &&
                  sidesHeightDelta * -1 > prevNodeHeight
                ) {
                  node.style.top = prevNodeTop + defaultMargin + 'px';
                } else {
                  node.style.top =
                    currentSideRef.current.clientHeight + defaultMargin + 'px';
                }
              }
            }

            const nodeTop = +helpers.position(node).top;
            const nodeHeight = +node.clientHeight;
            currentSideRef.current.style.height = nodeTop + nodeHeight + 'px';
          }

          setNodesHaveSet(true);
        }
      }
    }
  }, [events, isMobile]);

  useEffect(() => {
    topBar.current = document.getElementsByClassName('topbar');
    indicatorBox.current = document.getElementsByClassName('indicatorBox');

    let currScrollOffset = 0;
    if (topBar.current.length) {
      currScrollOffset = currScrollOffset + topBar.current[0].clientHeight;
    }
    if (indicatorBox.current.length) {
      currScrollOffset =
        currScrollOffset + indicatorBox.current[0].clientHeight;
    }
    setScrollOffset(currScrollOffset);
  }, []);

  useEffect(() => {
    if (isMobile) {
      eventsBoxRef.current.style.height = '';
    } else {
      if (nodesHaveSet) {
        eventsBoxRef.current.style.height =
          Math.max(
            sideLeftRef.current.clientHeight,
            sideRightRef.current.clientHeight
          ) + 'px';
      }
    }
  }, [events, nodesHaveSet, isMobile]);

  return (
    <div
      className={classNames(s.EventsList, {
        [s._firstloading]: eventsFirstLoading,
        [s._loading]: isLoading,
        [s._empty]: isEmpty
      })}
    >
      <div className={s.EventsList__grid}>
        <div className={s.EventsList__events} ref={eventsBoxRef}>
          <div className={s.EventsList__eventsSide} ref={sideLeftRef} />
          <div className={s.EventsList__eventsSide} ref={sideRightRef} />
          <div className={s.EventsList__eventsList} ref={listRef}>
            {events &&
              events.map((event) => (
                <EventsItem
                  key={event.id}
                  event={event}
                  activeEventId={isMobile ? event.id : activeEventId}
                  selectedEventId={selectedEventId}
                  onEventSelected={() => onEventSelected(event.id)}
                  onEventInView={onEventInView}
                  scrollOffset={scrollOffset}
                />
              ))}
          </div>
        </div>
      </div>
      <div className={s.EventsList__ext}>
        {isEmpty && (
          <div className={s.EventsList__empty}>
            <p>Список событий пуст</p>
          </div>
        )}
        {isLoading && (
          <div className={s.EventsList__loader}>
            <Loader />
          </div>
        )}
        {isLoaded && !allEventsLoaded && (
          <div className={s.EventsList__showMore}>
            <Button
              className={s.EventsList__moreBtn}
              text={'Загрузить еще..'}
              onClick={onGetMoreEvents}
            />
          </div>
        )}
      </div>
    </div>
  );
}
