import React from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import s from './Select.module.scss';
import { ReactComponent as DropDown } from '../../assets/img/icons/angle-down.svg';

const DropdownIndicator = (props) => {
  // const { value, onChange } = props.selectProps;
  return (
    <components.DropdownIndicator {...props}>
      <DropDown className={s.Select__dropDown} />
    </components.DropdownIndicator>
  );
};

const Input = (props) => {
  return <components.Input {...props} className={s.Select__input} />;
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div
        className={classNames(s.Select__content, {
          [s._selected]: props.isSelected
        })}
      >
        {props.label}
      </div>
    </components.Option>
  );
};

export const customComponents = {
  DropdownIndicator,
  Input,
  Option
  // ValueContainer
};
