import React, { useEffect, useMemo, useState } from 'react';
import { MainLayout } from '../../common/Layouts/Layouts';
import { LayoutBox } from '../../common/Layouts/LayoutBox';
import { TitleBox } from './TitleBox/TitleBox';
import { AnalyticsBox } from './AnalyticsBox/AnalyticsBox';
import { EventsIndicatorBox } from './EventsIndicatorBox/EventsIndicatorBox';
import { EventsList } from './EventsList/EventsList';
import { useAxiosQuery } from '@proscom/prostore-axios-react';
import { apiPath } from '../../config';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { TopBar } from '../../common/TopBar/TopBar';
import { skipIfNull } from '@proscom/prostore';
import { RightPanel } from '../../common/RightPanel/RightPanel';
import { useStore } from '@proscom/prostore-react';
import { STORE_AUTH } from '../../store/stores';
import { Loader } from '../../common/Loader/Loader';
import s from './IndexPage.module.scss';
import { updateDataPagination } from '../../utils/data';

const eventsPerPage = 20;

const protestsQueryOptions = {
  query: {
    url: apiPath + '/protests',
    method: 'get',
    params: {
      limit: 'all'
    }
  }
};
const eventsQueryOptions = {
  skipQuery: skipIfNull(null),
  updateData: updateDataPagination(eventsPerPage)
  /**
   * { events: [], pagination: {} }
   */
};
const eventsTimelineQueryOptions = {
  skipQuery: skipIfNull(null)
};

const getFirstLastDates = (events) => {
  const dates = events.map((event) => event.event_datetime);
  return {
    firstDateEventId: events[events.length - 1]
      ? events[events.length - 1].id
      : null,
    lastDateEventId: events[0] ? events[0].id : null,
    firstDate: dates.length ? dates[dates.length - 1] : null,
    lastDate: dates.length ? dates[0] : null
  };
};

export default function IndexPage({ location, history }) {
  const [auth, authStore] = useStore(STORE_AUTH);
  const [page, setPage] = useState(1);
  const [query, changeQuery] = useUrlQuery(location, history);

  const user = auth && auth.authData && auth.authData.user;

  // PROTESTS
  const protestsQuery = useAxiosQuery({
    queryOptions: protestsQueryOptions
  });

  const protests =
    protestsQuery.state.data && protestsQuery.state.data.success
      ? protestsQuery.state.data.data.protests
      : [];

  // console.log('protests', protests);

  const protestId = query.protest
    ? +query.protest
    : protests.length
    ? protests[0].id
    : undefined;

  // EVENTS
  const eventsVariables = useMemo(() => {
    return protests.length
      ? {
          url: `/api/events`,
          params: {
            protest_id: protestId,
            limit: eventsPerPage,
            page
          }
        }
      : null;
  }, [protests, protestId, page]);

  const eventsQuery = useAxiosQuery({
    queryOptions: eventsQueryOptions,
    variables: eventsVariables
  });

  const events =
    (eventsQuery.state.data && eventsQuery.state.data.events) || [];
  const pagination =
    (eventsQuery.state.data && eventsQuery.state.data.pagination) || undefined;

  const eventsFirstLoading =
    eventsQuery.state.loading && !eventsQuery.state.loaded;
  const eventsLoading = eventsQuery.state.loading;
  const allEventsLoaded = pagination && page === pagination.totalPageCount;

  // console.log('events', eventsQuery);

  const eventId = events && query.event ? +query.event : undefined;
  const event = eventId ? events.find((ev) => ev.id === eventId) : undefined;

  useEffect(() => {
    setPage(1);
  }, [protestId]);

  // TIMELINE
  const eventsTimelineVariables = useMemo(() => {
    return protests.length
      ? {
          url: `/api/events/timeline`,
          params: {
            protest_id: protestId,
            limit: 'all'
          }
        }
      : null;
  }, [protests, protestId]);
  

  const eventsTimelineQuery = useAxiosQuery({
    queryOptions: eventsTimelineQueryOptions,
    variables: eventsTimelineVariables
  });

  const eventsTimelineFirstLoading =
    eventsTimelineQuery.check && eventsTimelineQuery.check.spinner;

  console.log(eventsTimelineQuery);

  const eventsTimeline =
    eventsTimelineQuery.state.data && eventsTimelineQuery.state.data.success
      ? eventsTimelineQuery.state.data.data
      : [];

  const firstLastDates = useMemo(() => {
    return eventsTimeline
      ? getFirstLastDates(
          eventsTimeline.filter((event) => event.event_datetime !== null)
        )
      : null;
  }, [eventsTimeline]);

  const onProtestChange = (protest) => {
    changeQuery({ protest: protest.value });
  };
  const onRightClose = () => {
    changeQuery({ event: undefined });
  };
  const onLogout = () => {
    authStore.logOut().then(() => {
      history.replace('/login');
    });
  };

  const onGetMoreEvents = () => {
    if (pagination && page < pagination.totalPageCount) {
      setPage(page + 1);
    }
  };

  const toolbarActive = window.top===window.self;

  return (
    <div className={s.IndexPage}>
      <MainLayout
        topBar={
          toolbarActive ?
              (<TopBar
            activeProtest={protestId}
            protests={protests}
            onProtestChange={onProtestChange}
            user={user}
            onLogout={onLogout}
          />) : false
        }
        ext={
          <RightPanel
            isActive={eventId !== undefined}
            event={event}
            onClose={onRightClose}
          />
        }
      >
        <LayoutBox className={s.IndexPage__main}>
          <TitleBox
            protests={protests}
            protestId={protestId}
            dates={firstLastDates}
            location={location}
            history={history}
          />
        </LayoutBox>

        <LayoutBox className={s.IndexPage__analytics}>
          <AnalyticsBox protests={protests} protestId={protestId} doc={null} />
        </LayoutBox>

        <LayoutBox className={s.IndexPage__eventsIndicator}>
          {eventsTimelineFirstLoading ? (
            <Loader />
          ) : (
            <EventsIndicatorBox
              firstLastDates={firstLastDates}
              events={eventsTimeline}
            />
          )}
        </LayoutBox>

        {eventsFirstLoading ? (
          <div className={s.IndexPage__eventsLoader}>
            <Loader />
          </div>
        ) : (
          <div className={s.IndexPage__eventsList}>
            <EventsList
              events={events}
              eventsFirstLoading={eventsFirstLoading}
              eventsLoading={eventsLoading}
              allEventsLoaded={allEventsLoaded}
              location={location}
              history={history}
              onGetMoreEvents={onGetMoreEvents}
            />
          </div>
        )}
      </MainLayout>
    </div>
  );
}
