import React from 'react';
import s from './Button.module.scss';
import classNames from 'classnames';

export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
};

export function Button({
  className,
  variant = BUTTON_VARIANTS.PRIMARY,
  text,
  type = 'button',
  children,
  onClick
}) {
  return (
    <button
      className={classNames(s.Button, className, {
        [s[`_${variant}`]]: !!variant
      })}
      onClick={onClick}
      type={type}
    >
      <span className={s.Button__content}>
        <span className={s.Button__text}>{text}</span>
        {children}
      </span>
    </button>
  );
}
