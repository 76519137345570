import React from 'react';
import { ReactComponent as IconArrow } from '../../assets/img/icons/arrow-back.svg';
import s from './Indicator.module.scss';
import classNames from 'classnames';

export function Indicator({ className, icon, title, value, link, isCountry }) {
  const style = isCountry
    ? {
        backgroundImage: `url(${icon})`
      }
    : {};

  return isCountry ? (
    <a
      href={link || '#'}
      className={classNames(s.Indicator, className, s._country)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className={s.Indicator__icon} style={style}>
        {!isCountry && <img src={icon} alt="" />}
      </div>
      <div className={s.Indicator__content}>
        <div className={s.Indicator__title}>
          <p>{title}</p>
        </div>
        <div className={s.Indicator__value}>
          <p>{value}</p>
        </div>
      </div>
      <div className={s.Indicator__arrow}>
        <IconArrow />
      </div>
    </a>
  ) : (
    <div className={classNames(s.Indicator, className)}>
      <div className={s.Indicator__icon} style={style}>
        {!isCountry && <img src={icon} alt="" />}
      </div>
      <div className={s.Indicator__content}>
        <div className={s.Indicator__title}>
          <p>{title}</p>
        </div>
        <div className={s.Indicator__value}>
          <p>{value}</p>
        </div>
      </div>
    </div>
  );
}
