import React from 'react';
import { ReactComponent as CalIcon } from '../../assets/img/icons/calendar.svg';
import s from './DatePeriod.module.scss';
import { EMDASH } from '../../utils/utf';

export function DatePeriod({ dateSince, dateTill }) {
  return (
    <div className={s.DatePeriod}>
      <i>
        <CalIcon />
      </i>
      <div className={s.DatePeriod__dates}>
        <p>
          {dateSince && dateTill ? (
            <>
              <span className={s.DatePeriod__date}>{dateSince || EMDASH}</span>{' '}
              - <span className={s.DatePeriod__date}>{dateTill || EMDASH}</span>
            </>
          ) : (
            EMDASH
          )}
        </p>
      </div>
    </div>
  );
}
