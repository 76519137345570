import { BehaviorStore } from '@proscom/prostore';

const initialState = {
  isFixed: false,
  activeEventId: null
};

export class EventsStore extends BehaviorStore {
  constructor() {
    super(initialState);
  }

  setFixed(isFixed) {
    this.setState({
      isFixed
    });
  }

  selectEvent(eventId) {
    this.setState({
      activeEventId: eventId
    });
  }
}
