import React, { useCallback, useEffect, useRef } from 'react';
import ReactSlick from 'react-slick';
import classNames from 'classnames';
import s from './Slider.module.scss';

export function Slider({
  className,
  sliderClassName,
  selectedIndex = 0,
  sliderProps,
  onSlideBeforeChange,
  onSlideAfterChange,
  ext,
  children
}) {
  const slickRef = useRef(null);
  const initialSlideRef = useRef(selectedIndex);
  const initialSlide = initialSlideRef.current;
  const currentSlide = useRef(selectedIndex);
  const slidesAmount = React.Children.count(children);

  useEffect(() => {
    if (slickRef.current && slidesAmount > 0) {
      const slideIndex = currentSlide.current;
      if (selectedIndex !== slideIndex) {
        slickRef.current.slickGoTo(selectedIndex);
      }
    }
  }, [selectedIndex, slidesAmount]);

  const onBeforeChange = useCallback(() => {
    onSlideBeforeChange();
  }, [onSlideBeforeChange]);

  const onAfterChange = useCallback(
    (index) => {
      if (currentSlide.current !== index) {
        currentSlide.current = index;
        onSlideAfterChange(index);
      }
    },
    [onSlideAfterChange]
  );

  return (
    <div className={classNames(s.Slider, className)}>
      {slidesAmount > 0 && (
        <ReactSlick
          className={classNames(s.Slick, sliderClassName)}
          ref={slickRef}
          dots={false}
          arrows={false}
          slidesToShow={1}
          centerPadding={0}
          swipeToSlide
          focusOnSelect
          infinite
          initialSlide={initialSlide}
          beforeChange={onBeforeChange}
          afterChange={onAfterChange}
          {...sliderProps}
        >
          {children}
        </ReactSlick>
      )}
      {ext}
    </div>
  );
}
