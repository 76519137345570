import { BehaviorStore } from '@proscom/prostore';
import { LOCAL_STORAGE_AUTH } from './LocalStorageStore';
import { SubscriptionManager } from '../utils/SubscriptionManager';
import { isEqual } from 'lodash-es';
import { apiPath } from '../config';

export const ERROR_SESSION_EXPIRED = 'Сессия устарела';
const loginPath = apiPath + '/login';
const logoutPath = apiPath + '/logout';

const clearState = {
  user: null
};

export class AuthStore extends BehaviorStore {
  localStorageStore;
  client;
  sub = new SubscriptionManager();

  constructor({ localStorageStore, client }) {
    super({
      authData: null,
      loaded: false,
      error: null
    });

    this.localStorageStore = localStorageStore;
    this.client = client;
  }

  subscribe() {
    this.sub.subscribe(
      this.localStorageStore.get$(LOCAL_STORAGE_AUTH),
      this._handleLocalStorageChange
    );
  }

  unsubscribe() {
    this.sub.destroy();
  }

  _handleLocalStorageChange = (authData) => {
    if (!isEqual(authData, this.state.authData)) {
      if (authData) {
        this.setState({
          authData
        });
      } else {
        this._setError(ERROR_SESSION_EXPIRED);
      }
    }

    if (!this.state.loaded) {
      this.setState({
        loaded: true
      });
    }
  };

  _saveToLocalStorage(authData) {
    this.localStorageStore.setItem(LOCAL_STORAGE_AUTH, {
      user: authData.user
    });
  }

  _setAuthenticationData(authData) {
    // console.log('authData', authData);
    this.setState({ authData });
    this._saveToLocalStorage(authData);
  }

  _setError(error) {
    this._setAuthenticationData(clearState);
    this.setState({
      error
    });
  }

  logOut = async () => {
    const result = await this.client.post(logoutPath);
    // console.log('logout', result);

    if (!result.data.success) {
      throw result;
    }

    const authData = { user: null };
    this._setAuthenticationData(authData);
    return authData;
  };

  loginWithEmail = async (email, password) => {
    const result = await this.client.post(loginPath, {
      email,
      password
    });
    if (!result.data.success) {
      throw result;
    }

    const authData = { user: result.data.data.user };

    this._setAuthenticationData(authData);
    return authData;
  };

  isLoggedIn() {
    const { authData } = this.state;
    // console.log(authData);
    return authData && authData.user;
  }

  setExpired = () => {
    this._setError('Сессия истекла. Пожалуйста, войдите заново');
  };
}
