import React from 'react';
import s from './EventIcon.module.scss';
import classNames from 'classnames';
import { EVENTS_SIDES } from '../../utils/constants';
import { ReactComponent as IconFire } from '../../assets/img/icons/fire.svg';

export function EventIcon({ className, side, hasViolence, isActive }) {
  const color = side ? EVENTS_SIDES[side].color : EVENTS_SIDES.default.color;

  return (
    <div
      className={classNames(s.EventIcon, className, s[`_${color}`], {
        [s._violence]: hasViolence,
        [s._active]: isActive
      })}
    >
      <div className={s.EventIcon__circle}>
        {hasViolence && (
          <div className={s.EventIcon__icon}>
            <IconFire />
          </div>
        )}
      </div>
    </div>
  );
}
