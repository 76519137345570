import React from 'react';
import s from './Event.module.scss';
import classNames from 'classnames';
import HTMLParse from 'html-react-parser';

export function Event({
  className,
  id,
  images,
  title,
  desc,
  onClick,
  isSelected
}) {
  const image = images && images.length ? images[0].file : null;
  const imgStyle = image
    ? {
        backgroundImage: `url("${image.src}")`
      }
    : {};

  const shortDesc = desc ? desc.short_description : null;

  return (
    <div
      className={classNames(s.Event, className, {
        [s._selected]: isSelected
      })}
      onClick={onClick}
    >
      {image && <div className={s.Event__img} style={imgStyle} />}
      <div className={s.Event__content}>
        <div className={s.Event__title}>
          <p>{title}</p>
        </div>
        {shortDesc && (
          <div className={s.Event__desc}>
            {shortDesc && HTMLParse(shortDesc)}
          </div>
        )}
      </div>
    </div>
  );
}
