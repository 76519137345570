import React from 'react';
import { EventIcon } from '../../../common/EventIcon/EventIcon';
import { reformatDate } from '../../../utils/date';
import { dateFormatStringSimple } from '../../../utils/constants';
import s from './EventsIndicator.module.scss';
import { useStoreState } from '@proscom/prostore-react';
import { STORE_EVENTS } from '../../../store/stores';

export function EventsIndicator({ firstLastDates, events }) {
  const eventsStoreState = useStoreState(STORE_EVENTS);
  const activeEventId = eventsStoreState.activeEventId;

  // console.log(activeEventId);

  const firstDateEventId = firstLastDates.firstDateEventId;
  const lastDateEventId = firstLastDates.lastDateEventId;
  const firstDate = firstLastDates.firstDate;
  const lastDate = firstLastDates.lastDate;

  console.log('events:');
  console.log({firstDateEventId, lastDateEventId, firstDate, lastDate});

  return (
    <div className={s.EventsIndicator}>
      <div className={s.EventsIndicator__scroll}>
        <div className={s.EventsIndicator__wrapper}>
          <div className={s.EventsIndicator__line}>
            <div className={s.EventsIndicator__dots}>
              {events.map((event, i) => (
                <div key={i} className={s.EventsIndicator__dot}>
                  <EventIcon
                    className={s.EventsIndicator__dotIcon}
                    side={event.side}
                    hasViolence={event.violence || false}
                    isActive={event.id === activeEventId}
                  />
                  {firstDate && event.id === firstDateEventId ? (
                    <div className={s.EventsIndicator__dotDate}>
                      <span>
                        {reformatDate(firstDate, dateFormatStringSimple)}
                      </span>
                    </div>
                  ) : lastDate && event.id === lastDateEventId ? (
                    <div className={s.EventsIndicator__dotDate}>
                      <span>
                        {reformatDate(lastDate, dateFormatStringSimple)}
                      </span>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
