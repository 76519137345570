import React from 'react';

export default function ErrorPage({ text, description, children }) {
  return (
    <div>
      {text && <div>{text}</div>}
      {description && <div>{description}</div>}
      {children && <div>{children}</div>}
    </div>
  );
}
