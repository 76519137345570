import React from 'react';
import s from './Loader.module.scss';
import classNames from 'classnames';

export function Loader() {
  return (
    <div className={s.Loader}>
      <div className={s.Loader__spinner}>
        <div className={classNames(s.Loader__dot, s._dot1)} />
        <div className={classNames(s.Loader__dot, s._dot2)} />
        <div className={classNames(s.Loader__dot, s._dot3)} />
        <div className={classNames(s.Loader__dot, s._dot4)} />
        <div className={classNames(s.Loader__dot, s._dot5)} />
        <div className={classNames(s.Loader__dot, s._dot6)} />
        <div className={classNames(s.Loader__dot, s._dot7)} />
        <div className={classNames(s.Loader__dot, s._dot8)} />
      </div>
    </div>
  );
}
