import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as IconClose } from '../../assets/img/icons/close.svg';
import classNames from 'classnames';
import { PhotoSlider } from '../PhotoSlider/PhotoSlider';
import s from './RightPanel.module.scss';
import ResizeObserver from 'react-resize-observer';
import HTMLParse from 'html-react-parser';

export function RightPanel({ isActive, event, onClose }) {
  let title = null;
  let desc = null;
  let images = null;
  const topRef = useRef(null);
  const [size, setSize] = useState(0);
  const [boxHeight, setBoxHeight] = useState(0);

  if (event) {
    title = event.title;
    desc = event.description
      ? event.description.full_description ||
        event.description.short_description
      : null;
    images =
      event.attachments && event.attachments.length ? event.attachments : null;
    images = images && images.map((img) => img.file);
  }

  useEffect(() => {
    if (isActive) {
      document.documentElement.classList.add('_right-panel-active');
    } else {
      document.documentElement.classList.remove('_right-panel-active');
    }
  }, [isActive]);

  const updateSize = useCallback((size) => {
    setSize(size);
  }, []);

  useEffect(() => {
    if (title && topRef.current) {
      setBoxHeight(size.height - topRef.current.clientHeight);
    }
  }, [title, size]);

  const contentStyle = {
    height: boxHeight
  };

  return (
    <>
      <div
        className={classNames(s.RightPanel__offset, {
          [s._active]: isActive
        })}
        onClick={onClose}
      />
      <div
        className={classNames(s.RightPanel, {
          [s._active]: isActive
        })}
      >
        <ResizeObserver onResize={updateSize} />
        <div className={s.RightPanel__wrapper}>
          <div className={s.RightPanel__top} ref={topRef}>
            <div className={s.RightPanel__title}>
              <p>{title}</p>
            </div>
            <div className={s.RightPanel__closeBtn} onClick={onClose}>
              <i>
                <IconClose />
              </i>
            </div>
          </div>
          <div className={s.RightPanel__main} style={contentStyle}>
            <div className={s.RightPanel__scroll}>
              <div className={s.RightPanel__content}>
                {images &&
                  images.length > 0 &&
                  (images.length > 1 ? (
                    <div className={s.RightPanel__slider}>
                      <PhotoSlider
                        className={s.RightPanelSlider}
                        slides={images}
                      />
                    </div>
                  ) : (
                    <div
                      className={s.RightPanel__photo}
                      style={{ backgroundImage: `url(${images[0].src})` }}
                    />
                  ))}
                {desc && HTMLParse(desc)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
