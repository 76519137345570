import React, { useCallback } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import { customComponents } from './common';
import s from './Select.module.scss';

const selectComponents = {
  DropdownIndicator: customComponents.DropdownIndicator,
  Input: customComponents.Input,
  Option: customComponents.Option
};

export function Select({
  className,
  clearable,
  defaultValue,
  disabled,
  frwdRef,
  height,
  loading,
  multiple = false,
  name,
  noOptionsMessage,
  onChange,
  loadingMessage,
  placeholder,
  searchable,
  showPlaceholder,
  values,
  value,
  variant,
  ...props
}) {
  const noOptionsMessageCb = useCallback(() => noOptionsMessage, [
    noOptionsMessage
  ]);
  const loadingMessageCb = useCallback(() => loadingMessage, [loadingMessage]);

  return (
    <div className={classNames(className, s.Select)} {...props}>
      <ReactSelect
        className="select-container"
        classNamePrefix="select"
        clearable={clearable}
        components={selectComponents}
        // defaultValue={defaultValue}
        ref={frwdRef}
        hideSelectedOptions={false}
        isLoading={loading}
        isSearchable={!!searchable}
        isDisabled={!!disabled}
        isMulti={!!multiple}
        // menuIsOpen // debug props
        loadingMessage={loadingMessageCb}
        name={name}
        noOptionsMessage={noOptionsMessageCb}
        onChange={onChange}
        options={values}
        placeholder={placeholder}
        showPlaceholder={showPlaceholder}
        value={value}
      />
    </div>
  );
}

Select.defaultProps = {
  loadingMessage: 'Загрузка',
  placeholder: 'Выберите...',
  showPlaceholder: false,
  noOptionsMessage: 'Не найдено'
};
