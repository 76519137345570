// import { getRandomInteger } from './math';

import { formatNumber } from './formatNumber';

export function singleItemArray(item, defaultValue = []) {
  return item ? [item] : defaultValue;
}

export function getFirst(array) {
  return (array && array[0]) || null;
}

// export const getRandomNumbersArray = (length, min = 0, max = 101) =>
//   new Array(length).fill(null).map(() => getRandomInteger(min, max));

export function tryParseJson(json, defaultValue = null) {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.error('Error parsing json', json, e);
    return defaultValue;
  }
}

export function compareNumericStrings(a, b) {
  const aNumber = +a;
  const bNumber = +b;
  const aNaN = isNaN(aNumber);
  const bNaN = isNaN(bNumber);
  if (aNaN && bNaN) {
    return a < b ? -1 : a > b ? 1 : 0;
  }
  if (aNaN) return 1;
  if (bNaN) return -1;
  return aNumber < bNumber ? -1 : aNumber > bNumber ? 1 : 0;
}

export function getFileSize(fileSize) {
  if (fileSize === null || fileSize === undefined) return null;

  let size = +fileSize;
  let suffix = 'Б';
  if (+fileSize >= 1024 * 1024) {
    size = formatNumber(+fileSize / 1024, 2);
    suffix = 'МБ';
  } else if (+fileSize >= 1024) {
    size = formatNumber(+fileSize / 1024, 2);
    suffix = 'КБ';
  }

  return {
    size,
    suffix
  };
}

export function updateDataPagination(perPage) {
  return (data, oldData, { variables }) => {
    // console.log(data, oldData, variables);

    if (!oldData || variables.params.page === 1) return data && data.data;
    const page = data.data.pagination.currentPage;
    const oldItems = oldData.events;
    const begin = oldItems.slice(0, (page - 1) * perPage);
    const end = oldItems.slice(page * perPage, oldItems.length - 1);
    // console.log(begin, end, data.events);
    return {
      pagination: data.data.pagination,
      events: [...begin, ...data.data.events, ...end]
    };
  };
}
