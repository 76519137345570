import React, { useCallback, useRef, useState } from 'react';
import { MainLayout } from '../../common/Layouts/Layouts';
import { LayoutBox } from '../../common/Layouts/LayoutBox';
import { Button } from '../../common/Button/Button';
import { Input } from '../../common/Input/Input';
import s from './loginPage.module.scss';
import { useContextStore, useStore } from '@proscom/prostore-react';
import { STORE_AUTH } from '../../store/stores';
import { Redirect } from 'react-router';
import classNames from 'classnames';

function LoginForm({ history, login, error }) {
  const [loginError, setLoginError] = useState(null);
  const loginInputRef = useRef(null);
  const passInputRef = useRef(null);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const values = {
        login: loginInputRef.current.value,
        password: passInputRef.current.value
      };

      setLoginError(null);
      login(values.login, values.password)
        .then(() => {
          console.log('logged in');
          return history.replace('/');
        })
        .catch((err) => {
          console.log('error', err);
          setLoginError(err);
        });
    },
    [history, login]
  );

  return (
    <div
      className={classNames(s.LoginForm, {
        [s._error]: !!loginError
      })}
    >
      <div className={s.LoginForm__title}>
        <p>Вход в систему</p>
      </div>
      <form onSubmit={handleSubmit} className={s.LoginForm__form}>
        <div className={s.LoginForm__inputs}>
          <div className={s.LoginForm__line}>
            <Input
              className={s.LoginForm__input}
              type={'email'}
              placeholder={'Email'}
              forwardedRef={loginInputRef}
              required={true}
            />
          </div>
          <div className={s.LoginForm__line}>
            <Input
              className={s.LoginForm__input}
              type={'password'}
              placeholder={'Пароль'}
              forwardedRef={passInputRef}
              required={true}
            />
          </div>
          <div className={s.LoginForm__line}>
            <Button
              className={s.LoginForm__button}
              text={'Войти'}
              type={'submit'}
            />
          </div>
        </div>
      </form>
      {(loginError || error) && (
        <div className={s.LoginForm__error}>
          <p>
            {error ||
              'Ошибка! Проверьте правильность введенных данных и попробуйте еще раз.'}
          </p>
        </div>
      )}
    </div>
  );
}

export default function LoginPage({ history }) {
  const [auth, authStore] = useStore(STORE_AUTH);

  if (authStore.isLoggedIn()) {
    return <Redirect to={'/'} />;
  }

  return (
    <div className={s.LoginPage}>
      <MainLayout className={s.LoginPage__Layout}>
        <div className={s.LoginPage__login}>
          <LayoutBox className={s.LoginPage__loginBox}>
            <LoginForm
              login={authStore.loginWithEmail}
              history={history}
              error={auth.error}
            />
          </LayoutBox>
        </div>
      </MainLayout>
    </div>
  );
}
