function env(variable, defaultValue) {
  if (typeof variable === 'undefined') {
    return defaultValue;
  }
  return variable;
}

// function requireEnv(variable, name) {
//   if (typeof variable === 'undefined') {
//     throw new Error(`Variable ${name} is required`);
//   }
//   return variable;
// }

export const configVariable = env(process.env.REACT_APP_CONFIG_VARIABLE);
export const apiPath = env(process.env.REACT_APP_API_PATH, '/api');
export const useAuth = true;
